import React, { useEffect } from 'react'
import * as S from './styles'
import { useForm } from 'antd/lib/form/Form'
import { useHistory } from 'react-router-dom'
import { useUserStore } from '../../stores/useUser'
import AntkInput from '../../components/Input'

function IndexUserFormModal ({ onClose, open, meetingId }) {
  const [form] = useForm()

  const history = useHistory()

  const defaultFirstName = useUserStore(state => state.firstName)
  const defaultLastName = useUserStore(state => state.lastName)
  const defaultEmail = useUserStore(state => state.email)
  const updateUserInfo = useUserStore(state => state.updateUserInfo)

  useEffect(() => {
    form.setFields([
      { name: 'firstName', value: defaultFirstName },
      { name: 'lastName', value: defaultLastName },
      { name: 'email', value: defaultEmail }
    ])
  }, [form, defaultFirstName, defaultLastName, defaultEmail])

  const onSubmit = () => {
    updateUserInfo(form.getFieldsValue())

    history.push('/meeting')
  }

  return (
    <S.Modal
      visible={open}
      onCancel={onClose}>
      <S.Title>Share the meeting id with your friend: {meetingId}</S.Title>

      <S.Title>Please fill with your data </S.Title>
      <S.Description>
        Choose how you want to be called,
        fill in your email address for identification and
        have access to complementary content of the broadcast.
      </S.Description>
      <S.AntdForm
        layout="vertical"
        form={form}
        onFinish={onSubmit}>
        <S.AntdFormItem
          label="First Name"
          name="firstName"
          rules={[
            {
              required: true,
              type: 'string',
              message: 'Please input your first name!'
            }
          ]}>
          <AntkInput placeholder="Your first name here..." type="text"/>
        </S.AntdFormItem>

        <S.AntdFormItem
          label="Last Name"
          name="lastName"
          rules={[
            {
              required: true,
              type: 'string',
              message: 'Please input your last name!'
            }
          ]}>
          <AntkInput placeholder="Your last name here..." type="text"/>
        </S.AntdFormItem>

        <S.AntdFormItem
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: 'string',
              message: 'Please input your email!'
            }
          ]}>
          <AntkInput placeholder="youremail@youremail.com" type="email"/>
        </S.AntdFormItem>

        <S.SubmitButton submit>Join</S.SubmitButton>
      </S.AntdForm>
    </S.Modal>
  )
}

export default IndexUserFormModal

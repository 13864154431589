import { Button } from 'antd'
import styled from 'styled-components'

export const CustomButton = styled(Button)`
  min-height: 2.5rem;
  height: 2.5rem;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.8rem;
  border-radius: 24px;
  border: none;

  &, &:active {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
  }

  ${(props) => props.type !== 'link' && `
    background: linear-gradient(90deg, #5A3AA6 0%, #215ED9 51.35%, #3ADCEB 100%) !important;
    
    &:disabled {
      color: $light_grey;
    }
    
    &:hover, &:disabled, &:active {
      background: linear-gradient(90deg, #5A3AA6 0%, #215ED9 51.35%, #3ADCEB 100%) !important;
    }
    
    &:not(:disabled):hover {
      background: linear-gradient(90deg, #7b44ff 0%, #3b7cff 51.35%, #39eeff 100%) !important;
    }
  `}
  
  ${(props) => props.type === 'link' && `
    box-shadow: none;
    color: #215ED9;
    border: #215ED9 solid 2px !important;
  
    &:hover, &:active, &:focus {
      color: #5A3AA6;
      border: #5A3AA6 solid 2px !important;
    }
  
    &:disabled {
      color: #16368f;
      border: #16368f solid 2px !important;
    }
  `}
`

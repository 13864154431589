import { InputProps } from 'antd/lib/input/Input';
import React, { Ref } from 'react';
import * as S from './styles';

export type AntkInputProps = InputProps & {
  inputRef?: Ref<unknown> | undefined | any;
  prefixCls?: string;
}

const AntkInput = ({ inputRef, ...props }: AntkInputProps) => {
  switch (props.type) {
    case 'password':
      return <S.AntdInput.Password ref={inputRef} {...props} />

    case 'hidden':
      return <S.AntdInput ref={inputRef} {...props} type="hidden"/>

    case 'radio':
      return <S.AntdInput ref={inputRef} {...props} value={props.prefixCls} type="radio"/>

    default:
      return <S.AntdInput ref={inputRef} {...props}/>
  }
}

export default AntkInput

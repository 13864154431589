import { ButtonProps } from 'antd/lib/button/button';
import React from 'react';
import * as S from './styles';

export type AntkButtonProps = ButtonProps & {
  submit?: boolean;
};

const AntkButton = ({ type = 'primary', children, style, submit, className, ...rest }: AntkButtonProps) => (
  <S.CustomButton
    className={ className }
    block
    htmlType={ submit ? 'submit' : 'button' }
    type={ type }
    { ...rest }>
    { children }
  </S.CustomButton>
);

export default AntkButton;

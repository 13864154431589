import React from 'react'
import { hot } from 'react-hot-loader/root'
import 'antd/dist/antd.css'
import './App.css'
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import Index from './pages/home'
import MobileAlertModal from './modals/MobileAlertModal'
import { QueryClient, QueryClientProvider } from 'react-query'
import Meeting from './pages/meeting'
import { GlobalStyles } from './styles/global'

export const defaultQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

function App () {
  return (
    <>
      <QueryClientProvider client={defaultQueryClient}>
        <GlobalStyles/>

        <BrowserRouter>
          <MobileAlertModal/>
          <Switch>
            <Route exact path="/meeting" component={Meeting}/>
            <Route exact path="/:meetingCode?" component={Index}/>
            <Redirect to="/" />
          </Switch>
        </BrowserRouter>

        {process.env.NODE_ENV === 'development' && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
      </QueryClientProvider>
    </>
  )
}

export default App

import styled from 'styled-components'
import { resetButton } from '../../styles/recipes/reset-button'
import Tooltip from 'antd/lib/tooltip'
import FormItem from 'antd/lib/form/FormItem'
import Form from 'antd/lib/form'
import AntdSelect from '../Select'
import Popover from 'antd/lib/popover'
import AntkButton from '../Button'

export const AntdPopover = styled(Popover)``

export const OverlayPopoverStyle = styled.div`
  .ant-popover-inner-content {
    padding: 0;
    background-color: transparent;
  }
`

export const Modal = styled.div`
  width: 26rem;
  
  padding: 1rem;
  
  background-color: #FFF;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 12%);
  border-radius: 20px;
`

export const Button = styled.button`
  ${resetButton};
  
  background-color: white;
  border: 1px solid #EDEDFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.17);
  border-radius: 50%;
  
  display: flex;
  justify-content: center;
  align-items: center;

  height: 2.5rem;
  width: 2.5rem;
  
  padding: .5rem;
  
  &:disabled {
    opacity: .6;
  }
`

export const AntkTooltip = styled(Tooltip)``

export const ButtonIcon = styled.img`
  height: 100%;
  width: 100%
`

export const AntdForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const AntdFormItem = styled(FormItem)`
  width: 100%;
`

export const AntkSelect = styled(AntdSelect)``

export const RequestButton = styled(AntkButton)``

export const Title = styled.h2`
  font-weight: bold;
  font-size: 1.4rem;
  text-align: center;
`

export const Description = styled.p`
  font-size: 1rem;
  text-align: center;
`

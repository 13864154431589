import React, { useEffect, useState } from 'react'

import * as S from './styles'
import iconConfig from '../../assets/icon-config.svg'
import { useForm } from 'antd/lib/form/Form'
import { checkIfIsFirefox } from '../../utils/mobile-check'

function Config ({ className, hasMicrophone, hasSpeaker, requestPermissions, microphoneId, selectMicrophone, microphones, speakerId, selectSpeaker, speakers }) {
  const [form] = useForm()

  useEffect(() => {
    form.setFields([
      { name: 'microphoneId', value: microphoneId },
      { name: 'speakerId', value: speakerId }
    ])
  }, [form, microphoneId, speakerId])

  const [isMicrophoneSelectVisible, setIsMicrophoneSelectVisible] = useState(false)

  useEffect(() => {
    if (!isMicrophoneSelectVisible) {
      return
    }

    requestPermissions()
  }, [isMicrophoneSelectVisible, requestPermissions])

  const popOverContent = () => (
    <S.Modal>
      <S.AntdForm layout="vertical" form={form} hideRequiredMark>
        <S.Title>Settings</S.Title>

        {!hasMicrophone && !hasSpeaker && (<>
          <S.Description>You need to enable permissions for speaker and microphone.</S.Description>
          <S.RequestButton onClick={requestPermissions}>Request Permissions</S.RequestButton>
        </>)}

        {hasMicrophone && microphones.length === 0 && (
          <S.Description>You don't have any microphone connected.</S.Description>
        )}

        {microphones.length > 0 && (<>
          <S.AntdFormItem
            label="Microphone"
            name="microphoneId"
            rules={[
              {
                required: true,
                type: 'string',
                message: 'Please select the microphone!'
              }
            ]}>
            <S.AntkSelect placeholder="Select the microphone..."
                          type="text"
                          onChange={value => selectMicrophone(value)}
                          valuesInObject={{
                            key: 'value',
                            value: 'label',
                            data: microphones
                          }}/>
          </S.AntdFormItem>
        </>)}

        {hasSpeaker && speakers.length === 0 && (
          <S.Description>You don't have any speaker connected.</S.Description>
        )}

        {speakers.length > 0 && (<>
          <S.AntdFormItem
            label="Speaker"
            name="speakerId"
            rules={[
              {
                required: true,
                type: 'string',
                message: 'Please select the speaker!'
              }
            ]}>
            <S.AntkSelect placeholder="Select the speaker..."
                          type="text"
                          onChange={value => selectSpeaker(value)}
                          valuesInObject={{
                            key: 'value',
                            value: 'label',
                            data: speakers
                          }}/>
          </S.AntdFormItem>
        </>)}

        {checkIfIsFirefox() && (<>
          <S.AntdFormItem
            label="Speaker"
            name="speakerId">
            <S.AntkSelect placeholder="Select the speaker..."
                          type="text"
                          valuesInObject={{
                            key: 'value',
                            value: 'label',
                            data: [{ label: 'System default speaker device', value: 'default' }]
                          }}/>
          </S.AntdFormItem>
        </>)}
      </S.AntdForm>
    </S.Modal>
  )

  return (
    <S.AntdPopover placement="top"
                   content={popOverContent}
                   trigger="click"
                   popupVisible={isMicrophoneSelectVisible}
                   overlayClassName="reset-antd-popover"
                   onVisibleChange={visible => setIsMicrophoneSelectVisible(visible)}>
      <S.Button className={className} onClick={() => setIsMicrophoneSelectVisible(microphones.length > 0)}>
        <S.ButtonIcon src={iconConfig}
                      alt="Click to open config modal."/>
      </S.Button>
    </S.AntdPopover>
  )
}

export default Config

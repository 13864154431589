import React, { useLayoutEffect, useState } from 'react'
import { isRunningOnMobileOrTablet } from '../../utils/mobile-check'

import * as S from './styles'

function MobileAlertModal () {
  const [showPopup, setShowPopup] = useState(false)

  useLayoutEffect(() => {
    setShowPopup(isRunningOnMobileOrTablet())
  }, [])

  return (
    <S.PopupOverlay show={showPopup}>
      <S.PopupOverlayBackground />

      <S.Popup>
        <div>
          <S.PopupTitle>Oops! We don't have <br/>support for mobile yet.</S.PopupTitle>
          <S.PopupText>Please access using a desktop device<br/> and try again. </S.PopupText>
          <S.PopupButton
            onClick={() => setShowPopup(false)}
          >
            Try anyway
          </S.PopupButton>
        </div>
      </S.Popup>
    </S.PopupOverlay>
  )
}

export default MobileAlertModal

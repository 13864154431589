export const environment = {
  zustandStoreVersion: 1,
  api: {
    baseUrl: '',
  },
  webrtc: {
    signalingUrl: 'ws://streaming-dev.urermes.tv:12776/callapp',
    secureSignalingUrl: 'wss://streaming-dev.urermes.tv:12777/callapp',
    iceServers: [
      { 
        urls: 'stun:streaming-dev.urermes.tv:12779'
      },
      { 
        urls: 'turn:streaming-dev.urermes.tv:12779',
        credentialType:'password',
        username:'remote',
        credential: '#remote19'
      }
    ] as RTCIceServer[]
  },
}

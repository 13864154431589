import Spin from 'antd/lib/spin';
import styled from 'styled-components'

export const PlayerContainer = styled.div`
  background-color: #202024;
  
  video {
    background-color: #202024;
    
    object-fit: contain;
  }
  
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Loading = styled(Spin)``;

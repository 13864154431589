import styled from 'styled-components'
import Form from 'antd/lib/form'
import FormItem from 'antd/lib/form/FormItem'
import AntkButton from '../../components/Button'
import AntkModal from '../../components/Modal'

export const Modal = styled(AntkModal)`
  max-width: 22rem;
`

export const Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  text-align: center;
  color: #343457;
`

export const Description = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: .8rem;
  text-align: center;
  color: #343457;
  opacity: .8;
  line-height: 1.5rem;
  
  margin-bottom: 1.5rem;
`

export const AntdForm = styled(Form)`
  width: 100%;
`

export const AntdFormItem = styled(FormItem)``

export const SubmitButton = styled(AntkButton)`
  margin-top: 1rem;
  width: 100%;
`

import { environment } from '../../environment'
import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'

export type UseUserStore = {
  uid: string,
  name: string;
  email: string;
  firstName?: string;
  lastName?: string;
  updateUserInfo: (info: { email: string, firstName: string, lastName: string }) => void;
}

export const useUserStore = create<UseUserStore>(
  devtools(
    persist((set, get) => ({
      uid: Math.random().toString(16).slice(2),
      name: '',
      email: '',
      updateUserInfo: ({ email, firstName, lastName }) => set({
        email,
        firstName,
        lastName,
        name: `${firstName} ${lastName}`
      })
    }), {
      name: '@zustand/useUserStore',
      version: environment.zustandStoreVersion
    }),
    '@zustand/useUserStore'
  )
)

export const sidebarWidth = 26 * 16
export const sidebarPadding = 1.5 * 16

export const sidebarWidthClosed = 0
export const sidebarPaddingClosed = 1 * 16

export const sidebarIconSize = 2 * 16
export const sidebarIconLeft = sidebarIconSize / 2

export const meetingModalMaskZIndex = 1000
export const meetingUIZIndex = 1200

export const meetingFooterHeight = 3.5 * 16

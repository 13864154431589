import React from 'react'
import { useMicrophoneStore } from '../../../stores/useMicrophone';

import * as S from './styles'

function ChatMicrophone ({ className }) {
  const isMicrophoneOn = useMicrophoneStore(state => state.isMicrophoneOn);
  const toggleMicrophone = useMicrophoneStore(state => state.toggleMicrophone);

  return (
    <S.MicrophoneButton className={className}
                        isMicrophoneOn={isMicrophoneOn}
                        toggleMicrophone={toggleMicrophone}
    />
  )
}

export default ChatMicrophone

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cardImage from '../../assets/home-image.svg';
import iconVideo from '../../assets/join-icon.svg';
import logoErmes from '../../assets/logo-ermes.svg';
import IndexUserFormModal from '../../modals/IndexUserFormModal';
import { useMeetingStore } from '../../stores/useMeeting';
import * as S from './styles';

const Index = ({ match: { params: { meetingCode } } }) => {
  const history = useHistory();

  const lastMeetingId = useMeetingStore(state => state.meetingId);
  const setMeetingId = useMeetingStore(state => state.setMeetingId);
  const setIsCaller = useMeetingStore(state => state.setIsCaller);

  const resetStore = useMeetingStore(state => state.resetStore);

  const [code, setCode] = useState(meetingCode || lastMeetingId);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setCode(lastMeetingId);
  }, [setCode, lastMeetingId]);

  useEffect(() => {
    setMeetingId(meetingCode || lastMeetingId);
  }, [meetingCode, lastMeetingId, setMeetingId]);

  const onClickToCreateRoom = () => {
    const meetingId = Math.random().toString(16).slice(2, 8);

    setMeetingId(meetingId);
    setIsCaller(true);
    setOpen(true);
  };

  return (
    <S.Container>
      <IndexUserFormModal open={ open } meetingId={ lastMeetingId } onClose={ () => setOpen(false) }/>

      <S.LogoErmes visibility="header" src={ logoErmes } alt="Ermes Logo"/>

      <S.Card>
        <S.CardContent>
          <S.LogoErmes visibility="card" src={ logoErmes } alt="Ermes Logo"/>

          <S.Subcontainer>
            <S.Title>Web Meeting</S.Title>
            <S.Subtitle>Welcome to Web Meeting</S.Subtitle>
            <S.Introtext>
              Join the live stream for groups and watch the broadcast with augmented reality features. <br/>
              You just need to click Join now and enjoy the immersive broadcast experience.
            </S.Introtext>
            <S.Mutetext>You will join as a listener, without camera and voice capabilities.</S.Mutetext>

            { !meetingCode && (
              <S.CodeContainer>
                <S.CodeInput
                  placeholder="Please, enter the code"
                  value={ code }
                  onChange={ event => setCode(event.target.value) }/>

                <S.CodeButton type="link" onClick={ () => {
                  setIsCaller(false);

                  history.push(`/${ code }`);
                } }>
                  Check Code
                </S.CodeButton>

                <S.CreateRoomButton type="link" onClick={ () => onClickToCreateRoom() }>
                  Create Room
                </S.CreateRoomButton>
              </S.CodeContainer>
            ) }

            { meetingCode && (
              <S.JoinButton onClick={ () => setOpen(true) }>
                <S.JoinButtonImage src={ iconVideo } alt="Join Video Icon"/>

                Join Now
              </S.JoinButton>
            ) }
          </S.Subcontainer>
        </S.CardContent>

        <S.CardImage src={ cardImage } alt="A man in a suit next to the Ermes app"/>
      </S.Card>
    </S.Container>
  );
};

export default Index;

import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { environment } from '../../environment'

const defaultState = {
  isSpeakerOn: null
}

export type UseSpeakerStore = {
  speakerId: string;
  isSpeakerOn: boolean | null;
  selectSpeaker: (speakerId: string) => void;
  toggleSpeaker: () => void;
  resetStore: () => void;
}

export const useSpeakerStore = create<UseSpeakerStore>(
  devtools(
    persist(
      (set, get) => ({
        speakerId: 'default',
        ...defaultState,
        selectSpeaker: (speakerId) => set({ speakerId }),
        toggleSpeaker: () => set({ isSpeakerOn: !get().isSpeakerOn }),
        resetStore: () => set({ ...defaultState })
      }), {
        name: '@zustand/useSpeaker',
        version: environment.zustandStoreVersion,
        onRehydrateStorage: () => {
          return savedState => {
            useSpeakerStore.setState({
              ...defaultState,
              speakerId: savedState?.speakerId || 'default'
            })
          }
        }
      }),
    '@zustand/useSpeaker'
  )
)

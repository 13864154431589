import { Ref, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useInterval, useMeasure } from 'react-use';
import { UseMeasureRect } from 'react-use/lib/useMeasure';

function getMouseMovePosition(event) {
  let eventDoc, doc, body;

  const rect = event.target.getBoundingClientRect();
  const x = event.clientX - rect.left; //x position within the element.
  const y = event.clientY - rect.top;  //y position within the element.

  console.log(rect, x, y);

  event = event || window.event; // IE-ism

  if (event.touches) {
    const [touch] = event.touches;

    event.pageX = touch.pageX;
    event.pageY = touch.pageY;
  }

  // If pageX/Y aren't available and clientX/Y are,
  // calculate pageX/Y - logic taken from jQuery.
  // (This is to support old IE)
  if (event.pageX == null && event.clientX != null) {
    eventDoc = (event.target && event.target.ownerDocument) || document;
    doc = eventDoc.documentElement;
    body = eventDoc.body;

    event.pageX = event.clientX +
      (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
      (doc && doc.clientLeft || body && body.clientLeft || 0);

    event.pageY = event.clientY +
      (doc && doc.scrollTop || body && body.scrollTop || 0) -
      (doc && doc.clientTop || body && body.clientTop || 0);
  }

  return {
    x: event.pageX,
    y: event.pageY,
  };
}

export type OnGestureEventStart = {
  type: 'START';
  x: number;
  y: number;
  screenWidth: number;
  screenHeight: number;
};

export type OnGestureEventEnd = {
  type: 'END';
};

export type OnGestureEventUpdate = {
  type: 'UPDATE';
  x: number;
  y: number;
  screenWidth: number;
  screenHeight: number;
};

export type OnGestureEvent = OnGestureEventStart | OnGestureEventEnd | OnGestureEventUpdate;

export type OnGestureCallback = (event: OnGestureEvent) => void;

const UPDATE_FRAMERATE = 1000 / 24;
const maxValueRepeatedToStop = 24;
const maxMissedValues = maxValueRepeatedToStop + 1;
let screenPointsCount = {};
let missedRepeatedValues = 0;

function useGesture(onGestureCallback: OnGestureCallback): [Ref<HTMLElement>] {
  const lastEventSent = useRef<OnGestureEvent>(null);
  const isOutScreen = useRef(false);
  const startEndGestureDebounceTimeoutId = useRef<NodeJS.Timeout>(null);

  const [isTouchingOnContainer, setIsTouchingOnContainer] = useState(false);

  const sendEvent = useCallback((event: OnGestureEvent) => {
    if (lastEventSent.current === null) {
      lastEventSent.current = event;

      return onGestureCallback(event);
    }

    if (event.type === 'UPDATE') {
      lastEventSent.current = event;

      const countingKey = `${event.x}_${event.y}`;
      const counting = screenPointsCount[countingKey] || 0;

      screenPointsCount[countingKey] = counting + 1;

      console.log(missedRepeatedValues);
      console.log(screenPointsCount);

      if (missedRepeatedValues > maxMissedValues) {
        missedRepeatedValues = 0;

        screenPointsCount = {
          [countingKey]: 0,
        };
      }

      if (screenPointsCount[countingKey] <= maxValueRepeatedToStop) {
        missedRepeatedValues++;

        return onGestureCallback(event);
      }

      lastEventSent.current = {
        type: 'END',
      };

      screenPointsCount[countingKey] = 0;

      setIsTouchingOnContainer(false);

      return {
        type: 'END',
      };
    }

    if (lastEventSent.current.type === 'START' && event.type === 'START')
      return;

    if (lastEventSent.current.type === 'END' && event.type === 'END')
      return;

    lastEventSent.current = event;

    onGestureCallback(event);
  }, [onGestureCallback, setIsTouchingOnContainer]);

  const sendStartEndGestureEvent = useCallback((event: OnGestureEvent) => {
    clearTimeout(startEndGestureDebounceTimeoutId.current);

    startEndGestureDebounceTimeoutId.current = setTimeout(() => {
      sendEvent(event);
    }, 32);
  }, [sendEvent]);

  const [gestureContainer, setGestureContainer] = useState<HTMLElement>(null);
  const [refMeasure, gestureProps] = useMeasure();

  const ref = useCallback(element => {
    console.log('element gesture', element)
    refMeasure(element);
    setGestureContainer(element);
  }, [refMeasure, setGestureContainer]);

  const refMousePos = useRef<{ x: number, y: number }>({ x: 0, y: 0 });
  const refContainerRect = useRef<UseMeasureRect>(null);

  useEffect(() => {
    refContainerRect.current = gestureProps;
  }, [gestureProps]);

  const setTouchingTrue = useCallback((event) => {
    console.log(event);

    setIsTouchingOnContainer(true);

    sendStartEndGestureEvent({
      type: 'START',
      x: refMousePos.current.x,
      y: refMousePos.current.y,
      screenHeight: refContainerRect.current.height,
      screenWidth: refContainerRect.current.width,
    });
  }, [sendStartEndGestureEvent]);

  const setTouchingFalse = useCallback((event) => {
    console.log(event);

    setIsTouchingOnContainer(false);

    sendStartEndGestureEvent({
      type: 'END',
    });
  }, [sendStartEndGestureEvent]);

  useEffect(() => {
    if (!gestureContainer)
      return;

    const setTrueEvents = ['pointerdown', 'mousedown', 'touchstart'];
    const setFalseEvents = ['pointerup', 'mouseup', 'touchcancel', 'dragleave'];

    for (const event of setTrueEvents) {
      gestureContainer.addEventListener(event, setTouchingTrue);
    }

    for (const event of setFalseEvents) {
      gestureContainer.addEventListener(event, setTouchingFalse);
    }

    const preventDrag = event => {
      event.preventDefault();
    };

    gestureContainer.addEventListener('drag', preventDrag);

    return () => {
      for (const event of setTrueEvents) {
        gestureContainer.removeEventListener(event, setTouchingTrue);
      }

      for (const event of setFalseEvents) {
        gestureContainer.removeEventListener(event, setTouchingFalse);
      }

      gestureContainer.removeEventListener('drag', preventDrag);
    };
  }, [gestureContainer, setTouchingTrue, setTouchingFalse]);

  console.log(gestureProps);

  useLayoutEffect(() => {
    const event = (event) => {
      const rect = refContainerRect.current;

      if (!rect)
        return;

      let { x, y } = getMouseMovePosition(event);

      const updateValue = value => {
        refMousePos.current = value;
      };

      const maxX = rect.left + rect.width;
      const maxY = rect.top + rect.height;

      if (x >= maxX)
        return updateValue(null);

      if (y >= maxY)
        return updateValue(null);

      const minX = rect.left;
      const minY = rect.top;

      if (x <= minX)
        return updateValue(null);

      if (y <= minY)
        return updateValue(null);

      x -= rect.left;
      y -= rect.top;

      updateValue({ x, y });
    };

    if(gestureContainer){
      gestureContainer.addEventListener('mousemove', event);
      gestureContainer.addEventListener('touchmove', event);
      gestureContainer.addEventListener('pointermove', event);
    }

    return () => {
      if(gestureContainer){
        gestureContainer.removeEventListener('mousemove', event);
        gestureContainer.removeEventListener('touchmove', event);
        gestureContainer.removeEventListener('pointermove', event);
      }
    };
  }, [gestureContainer]);

  useInterval(() => {
    if (isOutScreen.current === false && refMousePos.current === null) {
      isOutScreen.current = true;

      sendStartEndGestureEvent({
        type: 'END',
      });

      setIsTouchingOnContainer(false);

      return;
    }

    if (isOutScreen.current === true && refMousePos.current !== null) {
      isOutScreen.current = false;

      sendStartEndGestureEvent({
        type: 'START',
        x: refMousePos.current.x,
        y: refMousePos.current.y,
        screenHeight: refContainerRect.current.height,
        screenWidth: refContainerRect.current.width,
      });
    }

    if (!refMousePos.current)
      return;

    sendEvent({
      type: 'UPDATE',
      x: refMousePos.current.x,
      y: refMousePos.current.y,
      screenHeight: refContainerRect.current.height,
      screenWidth: refContainerRect.current.width,
    });
  }, isTouchingOnContainer ? UPDATE_FRAMERATE : null);

  return useMemo(() => [ref], [ref]);
}

export default useGesture;

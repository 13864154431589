import styled from 'styled-components'
import SVG from 'react-inlinesvg'

export const Timer = styled.div`
  padding: .25rem 1rem;
  height: 2rem;
  
  display: flex;
  align-items: center;

  background-color: rgba(7, 7, 51, .6);
  border-radius: 16px;
`

export const Icon = styled(SVG)`
  height: 1.25rem;
  width: 1.25rem;

  & path {
    color: white;
  }
`

export const Text = styled.p`
  margin-bottom: 0;
  margin-left: .5rem;

  font-weight: bold;
  color: white;
  font-size: 1rem;
`

import React from 'react'
import { useDevicesStore } from '../../../stores/useDevices';
import { useMicrophoneStore } from '../../../stores/useMicrophone';
import { useSpeakerStore } from '../../../stores/useSpeaker'
import * as S from './styles'

function ChatConfig ({ className }) {
  const microphoneId = useMicrophoneStore(state => state.microphoneId)
  const setMicrophone = useMicrophoneStore(state => state.setMicrophone)

  const speakerId = useSpeakerStore(state => state.speakerId)
  const selectSpeaker = useSpeakerStore(state => state.selectSpeaker)

  const microphones = useDevicesStore(state => state.audioDevices)
  const speakers = useDevicesStore(state => state.speakerDevices)

  const hasMicrophone = useDevicesStore(state => state.hasMicrophone)
  const hasSpeaker = useDevicesStore(state => state.hasSpeaker)
  const requestPermissions = useDevicesStore(state => state.requestPermissions)

  return (
    <S.ConfigButton className={className}
                    requestPermissions={requestPermissions}
                    hasSpeaker={hasSpeaker}
                    hasMicrophone={hasMicrophone}
                    microphoneId={microphoneId}
                    selectMicrophone={setMicrophone}
                    microphones={microphones}
                    speakerId={speakerId}
                    selectSpeaker={selectSpeaker}
                    speakers={speakers}
    />
  )
}

export default ChatConfig

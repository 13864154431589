import styled from 'styled-components'
import StreamPlayer from '../../components/StreamPlayer'
import StreamPlayerBackground from '../../components/StreamPlayerBackground'
import Timer from '../../components/Timer'
import { toRem } from '../../styles/functions'
import { resetButton } from '../../styles/recipes/reset-button'
import ChatConfig from './config'
import ChatMicrophone from './microphone'
import { meetingFooterHeight, meetingUIZIndex, sidebarWidthClosed } from './variables'

export const Container = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
`

export const PlayerExitButton = styled.img`
  ${ resetButton };

  height: 2.5rem;
  width: 2.5rem;

  position: absolute;
  top: 2rem;
  left: 2rem;

  z-index: ${ meetingUIZIndex };
`

export const PlayerContainer = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;

  position: relative;

  ${ props => props.isDesktop && `
    min-width: calc(100% - ${ toRem(sidebarWidthClosed) });
    width: calc(100% - ${ toRem(sidebarWidthClosed) });
    max-width: calc(100% - ${ toRem(sidebarWidthClosed) });
  ` };
`

export const VideoContainer = styled.div`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;

  ${ props => props.isDesktop && `
    min-height: calc(100% - ${ toRem(meetingFooterHeight) });
    height: calc(100% - ${ toRem(meetingFooterHeight) });
    max-height: calc(100% - ${ toRem(meetingFooterHeight) });
  ` }
`

export const Canvas = styled.canvas`
  position: absolute;
  z-index: 999;
  background:transparent;

  ${ props => props.isDesktop && `
  min-height: calc(100% - ${ toRem(meetingFooterHeight) });
  height: calc(100% - ${ toRem(meetingFooterHeight) });
  max-height: calc(100% - ${ toRem(meetingFooterHeight) });
` }
`

export const LocalContainer = styled.div`
  position: absolute;
  z-index: 1000;

  top: 1rem;
  right: 1rem;

  height: 9rem;
  width: 16rem;

  cursor: pointer;

  background-color: #202024;
`;

export const VideoPlayer = styled(StreamPlayer)`
  height: 100%;
  width: 100%;
`

export const VideoBackground = styled(StreamPlayerBackground)`
`;

export const MicrophoneButton = styled(ChatMicrophone)`
  margin: 0 .5rem;
`

export const ConfigButton = styled(ChatConfig)`
  margin: 0 .5rem;
`

export const TimerCounter = styled(Timer)`
  position: absolute;

  top: .75rem;
  right: 1rem;
`

export const Footer = styled.div`
  background-color: #343457;
  min-height: ${ toRem(meetingFooterHeight) };
  height: ${ toRem(meetingFooterHeight) };

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: .5rem 2rem;
`

export const RequestingAccessIcon = styled.img`
  height: 4rem;
  width: 4rem;

  position: absolute;

  bottom: 5rem;
  right: 1rem;

  z-index: ${ meetingUIZIndex };
`

import styled from 'styled-components'
import AntkButton from '../../components/Button'
import AntkInput from '../../components/Input'

export const Container = styled.main`
  height: 100%;
  width: 100%;
  
  display: flex;
  align-items: center;
  
  flex-direction: column;
  overflow-y: auto;
  
  padding: 1rem 2rem;
`

export const Card = styled.div`
  max-width: 1280px;

  box-shadow: 0px 6px 18px 0px rgb(0 0 0 / 20%);
  border-radius: 10px;

  display: flex;
  align-items: center;

  background-color: #FFF;
  
  margin-top: auto;
  margin-bottom: auto;
  
  padding: 2rem 5rem;
  
  @media(max-width: 768px) {
    flex-direction: column-reverse;
    
    padding: 2rem;
  }
`

export const LogoErmes = styled.img`
  ${props => props.visibility === 'header' && `
    display: none;
      
    @media (max-width: 768px) {
      display: block;
      margin-bottom: 1rem;
    }
  `}
  
  ${props => props.visibility === 'card' && `
    @media (max-width: 768px) {
      display: none;
    }
  `}
`

export const CardContent = styled.div`
  flex: 1;

  margin-top: 3rem;
  
  @media(max-width: 768px) {
    margin-top: 1rem;
  }
`

export const CardImage = styled.img`
  width: 350px;
  height: 350px;

  margin-left: 2rem;

  @media(max-width: 768px) {
    width: 160px;
    height: 160px;
  }
`

export const Subcontainer = styled.div`
  margin-left: 2.5rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
  
  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    align-items: center;
  }
`

export const Subtitle = styled.h2`
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  margin: 10px 0 0 0;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 15px;
  }
`

export const Title = styled.h1`
  margin-top: 5rem;
  margin-bottom: .5rem;

  font-family: Mitr;
  font-style: normal;
  font-weight: 500;
  font-size: 3rem;
  line-height: 40px;
  
  display: flex;
  align-items: center;
  text-align: center;

  /* Interaction Gradient */

  //color: linear-gradient(90deg, #5A3AA6 0%, #215ED9 51.35%, #3ADCEB 100%);
  color: #5A3AA6;

  /* Fallback: Set a background color. */
  background-color: #5A3AA6;

  /* Create the gradient. */
  background-image: linear-gradient(90deg, #5A3AA6 0%, #215ED9 51.35%, #3ADCEB 100%);

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;

  @media (max-width: 768px) {
    margin-top: 1rem;
    
    font-size: 2rem;
    justify-content: center;
  }
`

export const Introtext = styled.p`
  color: #5A5A81;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  margin: 24px 0 0 0;

  @media (max-width: 780px) {
    font-size: 16px;
    text-align: center;
  }
`

export const Mutetext = styled.p`
  color: #ADADCC;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  margin: 24px 0 24px 0;

  @media (max-width: 768px) {
    font-size: 14px;
    text-align: center;
  }
`

export const ErrorMessage = styled.p`
  margin: 1rem 0;
  
  font-size: 1rem;
  
  color: red;
`

export const ErrorButton = styled(AntkButton)`
  width: 13rem;
`

export const CodeContainer = styled.div`
  display: flex;
  
  @media(max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

export const CodeInput = styled(AntkInput)`
  width: 13rem;
  
  margin-right: 1rem;

  @media(max-width: 768px) {
    text-align: center;
    margin-right: 0;
    margin-bottom: 1rem;
  }
`

export const CodeButton = styled(AntkButton)`
  width: 9rem;
`

export const CreateRoomButton = styled(AntkButton)`
  width: 9rem;
  margin-left: 1rem;
`

export const JoinButton = styled(AntkButton)`
  width: 13rem;

  display: flex;
  align-items: center;
  justify-content: center;
  
  img {
    margin-right: .5rem;
  }
  
  @media(max-width: 768px) {
    margin: 0 auto;
  }
`

export const JoinButtonImage = styled.img`
  height: 0.8rem;
  width: 1rem;
`

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import iconMicrophoneOff from '../../assets/icon-microphone-off.svg'
import { createMeetingAlertNotification } from '../../services/notification';

import * as S from './styles';

function StreamPlayerBackground({ streamVideo, className, sinkId }) {
  const [streamId] = useState(() => `stream_${ Math.random().toString(16).slice(2) }`);
  const containerRef = useRef<HTMLElement>(null);

  useLayoutEffect(() => {
    console.log(streamVideo);

    if (!streamVideo)
      return;

    const parentVideo = containerRef.current;

    if (!parentVideo)
      return;

    if (!(streamVideo instanceof HTMLVideoElement))
      return;

    parentVideo.appendChild(streamVideo);

    streamVideo.controls = false;

    return () => {
      if (streamVideo.parentElement !== parentVideo)
        return;

      parentVideo?.removeChild(streamVideo);
    }
  }, [streamVideo, streamId]);

  useEffect(() => {
    if (!sinkId)
      return;

    if (!streamVideo)
      return;

    if (!(streamVideo instanceof HTMLMediaElement))
      return;

    if (!(streamVideo as any).setSinkId)
      return createMeetingAlertNotification(iconMicrophoneOff, 'Your browser don\'t support change your speaker.');

    (streamVideo as any).setSinkId(sinkId);
  }, [sinkId, streamVideo])

  return (
    <S.PlayerContainer ref={ containerRef } className={ className } id={ streamId }>
      { !(streamVideo instanceof HTMLVideoElement) && (
        <S.Loading/>
      ) }
    </S.PlayerContainer>
  )
}

export default StreamPlayerBackground

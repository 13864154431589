import React from 'react'

import * as S from './styles'

function AntkModal ({ onCancel, children, backgroundColor = '#FFF', ...props }) {
  return (
    <S.AntdModal
      onCancel={onCancel}
      maskClosable={true}
      modalRender={() => (
        <S.ModalContent hasCloseButton={!!onCancel} backgroundColor={backgroundColor}>
          {onCancel && (
            <S.CloseButton onClick={onCancel}/>
          )}

          {children}
        </S.ModalContent>
      )}
      {...props}
    />
  )
}

export default AntkModal

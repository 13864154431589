import styled from 'styled-components'

import imgPopupAudience from '../../assets/mobile-not-supported.png'

export const PopupOverlay = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  flex: 1;
  align-items: center;
  justify-content: center;
  justify-items: center;
`

export const PopupOverlayBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: .5;
  z-index: 600;
`

export const Popup = styled.div`
  background: #fff url(${imgPopupAudience}) center 40px no-repeat;
  z-index: 999;
  padding: 180px 32px 50px 32px;
  border-radius: 24px;
`

export const PopupTitle = styled.div`
  width: 100%;
  font-family: Mitr;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
`

export const PopupText = styled.p`
  text-align: center;
  font-size: 17px;
  color: #343457;
  opacity: 0.8;

  margin: 16px 0;
`

export const PopupButton = styled.div`
  width: 100%;
  height: 48px;

  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-items: center;

  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  color: #5A3AA6;
  border: 2px solid #215ED9;
  border-style: inset;
  box-sizing: border-box;

  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
`

import create from 'zustand'
import { devtools } from 'zustand/middleware'
import { requestPermissions } from './services'

export type DeviceInfo = { value: string, label: string };

export type UseDevicesStore = {
  devices: DeviceInfo[];
  audioDevices: DeviceInfo[];
  videoDevices: DeviceInfo[];
  speakerDevices: DeviceInfo[];
  isRequestingPermissions: boolean;
  hasCamera: boolean | null;
  hasMicrophone: boolean | null;
  hasSpeaker: boolean | null;
  hasMicrophonePermissions: boolean | null;
  hasCameraPermissions: boolean | null;

  requestPermissions: () => void;
}

export const defaultValues: Omit<UseDevicesStore, 'requestPermissions'> = {
  devices: [],
  audioDevices: [],
  videoDevices: [],
  speakerDevices: [],
  isRequestingPermissions: false,
  hasCamera: null,
  hasMicrophone: null,
  hasSpeaker: null,
  hasMicrophonePermissions: null,
  hasCameraPermissions: null,
}

export const useDevicesStore = create<UseDevicesStore>(
  devtools(
    (set, get) => ({
      ...defaultValues,
      resetStore: () => set({ ...defaultValues }),
      requestPermissions: requestPermissions(set, get),
      resetToGetPermissionAgain: () => set({
        ...defaultValues,
      }),
    }),
    '@zustand/useDevicesStore',
  ),
)

import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useMedia } from 'react-use';

import iconMeetingExit from '../../assets/icon-meeting-exit.svg';
import { environment } from '../../environment';
import useCanvas from '../../hooks/useCanvas';
import useGesture from '../../hooks/useGesture';
import { usePeerWebRTC } from '../../hooks/usePeerWebRTC';
import { DataChannelOptions, MediaConfig, NetworkConfig } from '../../libs/awrtc';
import { useMeetingStore } from '../../stores/useMeeting';
import { useMicrophoneStore } from '../../stores/useMicrophone';
import { useSpeakerStore } from '../../stores/useSpeaker';
import MeetingModals from './modals';
import * as S from './styles';

function MeetingV2() {
  const isMobile = useMedia('(max-width: 768px)');

  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);

  const [shouldShowModalExit, setShouldShowModalExit] = useState(false);

  const isMicrophoneOn = useMicrophoneStore(state => state.isMicrophoneOn);
  const microphoneId = useMicrophoneStore(state => state.microphoneId);

  const speakerId = useSpeakerStore(state => state.speakerId);
  const resetSpeakerStore = useSpeakerStore(state => state.resetStore);

  const meetingId = useMeetingStore(state => state.meetingId);
  const isCaller = useMeetingStore(state => state.isCaller);

  const [
    refCanvas, 
    contextRef, 
    startDrawing, 
    finishDrawing, 
    draw
  ] = useCanvas(canvasRef)

  const [netConfig] = useState(() => {
    const config = new NetworkConfig();

    config.IsConference = false;
    config.SignalingUrl = environment.webrtc.secureSignalingUrl;
    config.IceServers = environment.webrtc.iceServers;

    console.log(config);

    return config;
  });

  const dataChannelOption = useMemo(() => {
    const channel = new DataChannelOptions();

    channel.label = 'user_drawing_events';

    channel.option = {
      ordered: true,
      maxPacketLifeTime: 2000,
    };

    channel.sendData = console.log;

    channel.onMessage = (event: any) => {
      const data = JSON.parse(event.data)
      console.log(data)
      const canvas = canvasRef.current;
   
      const w = canvas.width/2;
      const h = canvas.height/2;

      if(data.type === 'END'){
        if (!isDrawing) return;
        contextRef.current.closePath();
        setIsDrawing(false);

        return
      }

      if(data.type === 'START'){
        contextRef.current.beginPath();
        contextRef.current.strokeStyle = 'black';

        contextRef.current.moveTo(data.x*w, data.y*h);
        setIsDrawing(true);
      }

      if(data.type === 'UPDATE'){
        if (!isDrawing) return;
        contextRef.current.lineTo(data.x*w, data.y*h);
        contextRef.current.stroke();
      }

      event && console.trace(data)
    };

    return channel;
  }, [contextRef, isDrawing]);

  const mediaConfig = useMemo(() => {
    const config = new MediaConfig();

    console.log(isMicrophoneOn);

    config.DataChannelOptions = [dataChannelOption];
    config.AudioOn = isMicrophoneOn;
    config.Audio = microphoneId === 'default' ? true : { deviceId: microphoneId };
    config.Video = true;
    config.FrameUpdates = false;

    return config;
  }, [microphoneId, isMicrophoneOn, dataChannelOption]);

  const [invertCamera, setInvertCamera] = useState(false);
  const [localVideo, remoteVideos] = usePeerWebRTC(meetingId, netConfig, mediaConfig, isCaller);

  const remoteVideo = useMemo(() => remoteVideos[0], [remoteVideos]);

  const topVideo = useMemo(() => invertCamera ? remoteVideo : localVideo, [invertCamera, localVideo, remoteVideo]);
  const backgroundVideo = useMemo(() => invertCamera || isMobile ? localVideo : remoteVideo, [invertCamera, localVideo, remoteVideo, isMobile]);


  const [refGesture] = useGesture(event => {

    const type = event.type

    if (event.type !== 'END'){
      const x = event.x / event.screenWidth;
      const y = event.y / event.screenHeight;

      dataChannelOption.sendData(JSON.stringify({ type, x, y }));
    }
    else{
      dataChannelOption.sendData(JSON.stringify({ type }));
    }

  });

  useEffect(() => {
    return () => {
      resetSpeakerStore();
    };
  }, [resetSpeakerStore]);

  return (
    <S.Container>
      <S.PlayerContainer>
        <MeetingModals shouldShowModalExit={ shouldShowModalExit }/>

        <S.PlayerExitButton onClick={ () => setShouldShowModalExit(true) }
                            src={ iconMeetingExit }
                            alt="Click here to exit from broadcast."/>

        <S.VideoContainer className="Aqui" isDesktop={ true } >
          <S.VideoBackground streamVideo={ backgroundVideo } ref={ refGesture } sinkId={ speakerId } background/> 
        </S.VideoContainer>

        { !shouldShowModalExit && (<>
          <S.Canvas
            isDesktop={ true } 
            onMouseDown={startDrawing}
            onMouseUp={finishDrawing}
            onMouseMove={draw}
            ref={canvasRef}
            >
            </S.Canvas>

            { !isMobile && (
              <S.LocalContainer onClick={ () => setInvertCamera(invert => !invert) }>
                <S.VideoPlayer   streamVideo={ topVideo } sinkId={ speakerId } background={false}/>
              </S.LocalContainer>
            ) }
          </>) 
        }

        <S.Footer>
          <S.MicrophoneButton/>
          <S.ConfigButton/>

          <S.TimerCounter stopTimer={ false }/>
        </S.Footer>
      </S.PlayerContainer>
    </S.Container>
  );
}

export default MeetingV2;

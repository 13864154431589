import { DeviceApi } from '../../libs/awrtc';
import { useMicrophoneStore } from '../useMicrophone';
import { useSpeakerStore } from '../useSpeaker'
import { checkIfIsFirefox } from '../../utils/mobile-check'
import { createMeetingAlertNotification } from '../../services/notification'
import iconMicrophoneOff from '../../assets/icon-microphone-off.svg'

export function requestPermissions (set, get) {
  return async () => {
    if (!DeviceApi.IsApiAvailable()) {
      set({
        isRequestingPermissions: false,
        hasMicrophonePermissions: false,
        hasCameraPermissions: false,
        videoDevices: [],
        audioDevices: [],
        hasCamera: false,
        hasMicrophone: false
      })

      return false
    }

    const callback: () => Promise<MediaDeviceInfo[]> = () => navigator.mediaDevices.enumerateDevices();

    set({ isRequestingPermissions: true })

    let couldGetPermissionToSpeak = false

    if (!checkIfIsFirefox()) {
      couldGetPermissionToSpeak = await navigator.mediaDevices?.getUserMedia({ audio: true })
        .then(() => true)
        .catch(() => false)
    } else if (checkIfIsFirefox()) {
      couldGetPermissionToSpeak = true
    }

    if (!couldGetPermissionToSpeak) {
      set({
        isRequestingPermissions: false
      })

      createMeetingAlertNotification(iconMicrophoneOff, 'We were unable to connect your audio to the host, you need to access browser settings to enable microphone permissions.')

      return
    }

    return callback()
      .then(devices => {
        const oldVideoDevices = get().videoDevices.reduce((acc, device) => {
          acc[device.value] = device

          return acc
        }, { })
        const oldAudioDevices = get().audioDevices.reduce((acc, device) => {
          acc[device.value] = device

          return acc
        }, { })

        const videoDevices = devices
          .filter((item) => item.kind === 'videoinput')
          .map((item, idx) => ({
            value: item.deviceId,
            label: item.label
              ? item.label
              : oldVideoDevices[item.deviceId]
                ? oldVideoDevices[item.deviceId].label
                : `Video Input ${idx}`
          }))
          .filter(item => !!item.value)

        const audioDevices = devices
          .filter((item) => item.kind === 'audioinput')
          .map((item, idx) => ({
            value: item.deviceId,
            label: item.label
              ? item.label
              : oldAudioDevices[item.deviceId]
                ? oldAudioDevices[item.deviceId].label
                : `Audio Input ${idx}`
          }))
          .filter(item => !!item.value)

        const speakerDevices = devices
          .filter((item) => item.kind === 'audiooutput')
          .map((item, idx) => ({
            value: item.deviceId,
            label: item.label ? item.label : `Audio Output ${idx}`
          }))
          .filter(item => !!item.value)

        const currentSpeaker = useSpeakerStore.getState().speakerId
        const currentMicrophone = useMicrophoneStore.getState().microphoneId

        if (speakerDevices.length > 0 && speakerDevices.every(speaker => speaker.value !== currentSpeaker)) { useSpeakerStore.getState().selectSpeaker('default') }

        if (audioDevices.length > 0 && audioDevices.every(speaker => speaker.value !== currentMicrophone)) { useMicrophoneStore.getState().setMicrophone('default') }

        set({
          devices,
          isRequestingPermissions: false,
          hasMicrophonePermissions: audioDevices.length > 0,
          hasCameraPermissions: videoDevices.length > 0,
          hasSpeakerPermissions: speakerDevices.length > 0,
          videoDevices,
          audioDevices,
          speakerDevices,
          hasCamera: videoDevices.length > 0,
          hasMicrophone: audioDevices.length > 0,
          hasSpeaker: speakerDevices.length > 0
        })

        return true
      })
      .catch(error => {
        console.error('[useDevicesStore]: Cannot get devices of user: ', error)

        set({
          isRequestingPermissions: false,
          hasMicrophonePermissions: false,
          hasCameraPermissions: false,
          videoDevices: [],
          audioDevices: [],
          hasCamera: false,
          hasMicrophone: false
        })

        return false
      })
  }
}

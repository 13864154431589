import React, { useCallback, useState } from 'react'

import iconTimer from '../../assets/icon-timer.svg'
import * as S from './styles'
import { useInterval } from 'react-use'

function getFormattedTime (seconds) {
  const second = seconds % 60
  const minute = Math.floor((seconds / 60) % 60)
  const hour = Math.floor(seconds / 60 / 60)

  const formatNumber = number => number > 9 ? number.toString() : `0${number}`

  if (hour > 0) { return `${formatNumber(hour)}:${formatNumber(minute)}:${formatNumber(second)}` }

  return `${formatNumber(minute)}:${formatNumber(second)}`
}

function Timer ({ className, stopTimer }) {
  const [second, setSecond] = useState(0)

  const updateSecondsCallback = useCallback(() => setSecond(seconds => seconds + 1), [setSecond])

  useInterval(updateSecondsCallback, stopTimer ? null : 1_000)

  return (
    <S.Timer className={className}>
      <S.Icon src={iconTimer} />
      <S.Text>{getFormattedTime(second)}</S.Text>
    </S.Timer>
  )
}

export default Timer

import styled from 'styled-components'
import Modal from 'antd/lib/modal'
import iconClose from '../../assets/icon-close.svg'

export const AntdModal = styled(Modal)`
  pointer-events: auto;
`

export const ModalContent = styled.div<{ hasCloseButton: boolean, backgroundColor: string }>`
  padding: ${props => props.hasCloseButton ? '3rem' : '2rem'} 2rem 2rem 2rem;

  background-color: ${props => props.backgroundColor};

  box-shadow: 0px 4px 10px rgb(0 0 0 / 12%);
  border-radius: 6px;
  border: 1px solid #5A5A81;
`

export const CloseButton = styled.button`
  outline: none;
  border: none;
  appearance: none;

  top: 1.5rem;
  right: 1.5rem;
  position: absolute;
  
  height: 1.3rem;
  width: 1.3rem;
  
  background-image: url("${iconClose}");
  background-size: 100% 100%;
  background-color: transparent;
  
  cursor: pointer;
`

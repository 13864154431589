import styled from 'styled-components'
import AntkModal from '../../../components/Modal'
import AntkButton from '../../../components/Button'
import { toRem } from '../../../styles/functions'
import { meetingFooterHeight, meetingModalMaskZIndex, sidebarWidthClosed } from '../variables'

export const Modal = styled(AntkModal)`
  max-width: 18rem;
  
  @media(min-width: 768px) {
    max-width: 22rem;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Icon = styled.img`
  height: 7rem;
  width: 7rem;
`

export const Title = styled.h2`
  margin-top: 2rem;
  
  font-weight: bold;
  font-size: 1.4rem;
  color: ${props => props.color || '#FFFFFF'};
  text-align: center;
`

export const SimpleTitle = styled.h2`
  font-weight: bold;
  font-size: 1.4rem;
  color: ${props => props.color || '#FFFFFF'};
  text-align: center;
`

export const Description = styled.p`
  margin-top: 1rem;

  font-size: 1rem;
  color: ${props => props.color || '#FFFFFF'};
  text-align: center;
`

export const Button = styled(AntkButton)`
  margin-top: 1rem;
`

export const MeetingModalContainer = styled.div`
  .ant-modal-root {
    width: 100%;
    height: 100%;
  }
  
  .ant-modal-mask {
    background-color: #202024;    
  }
  
  .ant-modal-wrap {
    display: flex;
    justify-content: center;
    align-items: center;

    z-index: ${meetingModalMaskZIndex};
  }
  
  .ant-modal {
    top: 0;
  }

  @media(min-width: 768px) {
    .ant-modal-root > div {
      min-width: calc(100% - ${toRem(sidebarWidthClosed)});
      width: calc(100% - ${toRem(sidebarWidthClosed)});
      max-width: calc(100% - ${toRem(sidebarWidthClosed)});
      
      height: calc(100% - ${toRem(meetingFooterHeight)});
    }
  }
`

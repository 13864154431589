import React from 'react'

import { useHistory } from 'react-router-dom'

import streamingInPause from '../../../assets/icon-streaming-in-pause.svg'

import * as S from './styles'

export const MEETING_MODALS_CONTAINER_ID = 'meeting_modals'
export const getContainer = () => document.getElementById(MEETING_MODALS_CONTAINER_ID)

function MeetingModals ({ shouldShowModalExit }) {
  const history = useHistory()

  return (<>
    <div>
      <S.MeetingModalContainer id={MEETING_MODALS_CONTAINER_ID}/>
    </div>

    <S.Modal
      visible={shouldShowModalExit}
      zIndex={1200}
      getContainer={getContainer}
      maskClosable={false}
      backgroundColor="#343457">
      <S.Content>
        <S.Icon src={streamingInPause} alt="The exit of stream"/>
        <S.Title>Thanks for using ermescast.com!</S.Title>

        <S.Button onClick={() => history.push('/')}>Back to Home</S.Button>
      </S.Content>
    </S.Modal>
  </>)
}

export default MeetingModals

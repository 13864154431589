import styled from 'styled-components'
import Input from 'antd/lib/input'

export const AntdInput = styled(Input)`
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 0.7rem;
  font-weight: 500;
  font-size: .8rem;
  line-height: 1rem;
  padding: 1rem 1.1rem 1rem 1.1rem;
  max-height: 2.5rem;
  cursor: text;

  &:hover {
    border-color: #bdbdbd;
  }

  &:focus {
    border-color: #5A3AA6;
  }

  &::placeholder, &:placeholder-shown {
    padding-top: .75rem;
    font-size: .8rem;
    line-height: 1rem;
    color: #bdbdbd;
    font-weight: 500;
  }

  input {
    font-weight: 500;
  }
`

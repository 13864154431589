import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';


export type useCanvasReturn = [RefObject<HTMLCanvasElement>, RefObject<CanvasRenderingContext2D>, (Event: any)=> void, ()=> void, (Event: any)=> void]

function useCanvas(ref: RefObject<HTMLCanvasElement>): useCanvasReturn{
  const canvasRef = useRef(null);
  const contextRef = useRef<CanvasRenderingContext2D>(null);
  const [isDrawing, setIsDrawing] = useState(false);

  useEffect(() =>{
    if(!ref) return
      canvasRef.current = ref.current;
  },[ref])

  useEffect(() =>{
    if(!canvasRef.current) return
    
    const canvas = canvasRef.current;
    canvas.width = canvas.clientWidth * 2;
    canvas.height = canvas.clientHeight * 2;

    const context = canvas.getContext('2d');

    if(!context) return

    context.clearRect(0, 0, canvas.width, canvas.height);

    context.scale(2, 2);
    context.lineCap = 'round';
    context.strokeStyle = 'blue'
    context.lineWidth = 5;

    contextRef.current = context;
  },[])

  const finishDrawing = useCallback(() => {
    contextRef.current.closePath();
    setIsDrawing(false);
  },[]);

  const startDrawing = useCallback(({ nativeEvent }) => {
    finishDrawing()

    const { offsetX, offsetY } = nativeEvent;
    contextRef.current.strokeStyle = 'blue'

    console.log(offsetX, offsetY)

    contextRef.current.beginPath();
    contextRef.current.moveTo(offsetX, offsetY);
    setIsDrawing(true);
  }, [finishDrawing]);

  const draw = useCallback(({ nativeEvent }) => {
    if (!isDrawing) {
      return;
    }

    const { offsetX, offsetY } = nativeEvent;
    contextRef.current.lineTo(offsetX, offsetY);
    contextRef.current.stroke();
  },[isDrawing]);

  return useMemo(() => [canvasRef, contextRef, startDrawing, finishDrawing, draw], [canvasRef, contextRef, startDrawing, finishDrawing, draw]);
}


export default useCanvas;
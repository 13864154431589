import React from 'react';
import { createGlobalStyle } from 'styled-components';

import './antd.less';

export const GlobalStyles = createGlobalStyle`
  code {
    font-family: source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html,
  body,
  div,
  span,
  object,
  iframe,
  figure,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  code,
  em,
  img,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  b,
  u,
  i,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  main,
  canvas,
  embed,
  footer,
  header,
  nav,
  section,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: baseline;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: none;
  }

  footer,
  header,
  nav,
  section,
  main {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  input {
    -webkit-appearance: none;
    border-radius: 0;
    border: none;
    outline: none;
  }

  .map-component-marker {
    background: transparent;
    border: 0;
  }

  .ant-modal {
    font-size: inherit;
  }

  @supports (padding-top: 20px) {
    html {
      --safe-area-top: var(--ion-statusbar-padding);
    }
  }

  @supports (padding-top: constant(safe-area-inset-top)) {
    html {
      --safe-area-top: constant(safe-area-inset-top);
      --safe-area-bottom: constant(safe-area-inset-bottom);
      --safe-area-left: constant(safe-area-inset-left);
      --safe-area-right: constant(safe-area-inset-right);
    }
  }

  @supports (padding-top: env(safe-area-inset-top)) {
    html {
      --safe-area-top: env(safe-area-inset-top);
      --safe-area-bottom: env(safe-area-inset-bottom);
      --safe-area-left: env(safe-area-inset-left);
      --safe-area-right: env(safe-area-inset-right);
    }
  }

  .reset-antd-popover .ant-popover-inner-content,
  .reset-antd-popover .ant-popover-inner {
    padding: 0;
    background-color: transparent;
  }

  .meeting-alert-notification {
    background-color: rgba(52, 52, 87, .8);
    color: white;
    border-radius: 6px;
    box-shadow: 0 10px 40px rgba(23, 63, 112, 0.1);
  }

  .meeting-alert-notification .ant-notification-notice-icon {
    margin-left: -.75rem;
  }

  .meeting-alert-notification .ant-notification-close-icon {
    color: white;
  }

  .meeting-alert-notification .ant-notification-notice-message {
    display: none;
  }

  .meeting-alert-notification .ant-notification-notice-close {
    height: 1.5rem;
    width: 1.5rem;
    top: 1rem;
  }

  .meeting-alert-notification .ant-notification-notice-description {
    padding-right: 2.5rem;
    text-align: justify;
  }

  .meeting-alert-notification .ant-notification-close-x,
  .meeting-alert-notification .ant-notification-close-icon,
  .meeting-alert-notification .ant-notification-close-x svg {
    height: 100%;
    width: 100%;
  }
`;

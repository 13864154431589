import { notification } from 'antd'
import React from 'react'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'

import { sidebarWidthClosed } from '../pages/meeting/variables'
import { toRem } from '../styles/functions'

const notificationStyle = {
  boxShadow: '0px 10px 40px rgba(23, 63, 112, 0.1)',
  borderRadius: '2rem'
}

notification.config({
  placement: 'bottomRight',
  duration: 0
})

export const createSuccessNotification = (description) => {
  notification.success({
    message: 'Success!',
    description: description,
    placement: 'bottomRight',
    style: notificationStyle,
    duration: 5
  })
}

const AlertIcon = styled(SVG)`
  margin-left: 1rem;
  
  height: 1.5rem;
  width: 1.5rem;
  
  & path {
    fill: white;
  }
`

export const createMeetingAlertNotification = (icon, description, duration = 5, bottom = 6) => {
  const remSize = parseInt(getComputedStyle(document.documentElement).fontSize)

  const sidebarWidthInPixels = sidebarWidthClosed
  const safeRightPosition = sidebarWidthInPixels + (remSize)

  notification.success({
    message: 'Alert!',
    description: description,
    placement: 'bottomRight',
    className: 'meeting-alert-notification',
    style: {
      marginRight: toRem(safeRightPosition)
    },
    duration,
    icon: <AlertIcon src={icon}/>,
    bottom: remSize * bottom
  })
}

let lastMessageAt
let lastMessage

export const createErrorNotification = (description) => {
  if (description === lastMessage && (lastMessageAt + 3000) >= +new Date()) { return }

  lastMessageAt = +new Date() + 3000
  lastMessage = description

  notification.error({
    message: 'Error!',
    description: description,
    placement: 'bottomRight',
    style: notificationStyle,
    duration: 5
  })
}

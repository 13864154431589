import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { BrowserWebRtcCall, CallEndedEventArgs, CallEventType, ConnectionId, MediaConfig, MediaUpdatedEventArgs, NetworkConfig } from '../../libs/awrtc';
import { useInterval } from 'react-use';
import { usePeerWebRTCStore } from '../../stores/usePeerWebRTC';

export function usePeerWebRTC(address: string, netConfig: NetworkConfig, mediaConfig: MediaConfig, isCaller: boolean): [HTMLVideoElement | null, HTMLVideoElement[]] {
  const [browserWebRTC, setBrowserWebRTC] = useState<BrowserWebRtcCall>(null);

  const localVideoRef = useRef<HTMLVideoElement>(null);

  const localVideo = usePeerWebRTCStore(state => state.localVideo);
  const setLocalVideo = usePeerWebRTCStore(state => state.setLocalVideo);
  const remoteVideos = usePeerWebRTCStore(state => state.remoteVideos);

  const onNewConnection = usePeerWebRTCStore(state => state.onNewConnection);
  const onRemoveConnection = usePeerWebRTCStore(state => state.onRemoveConnection);

  const hasConnectionId = usePeerWebRTCStore(state => state.hasConnectionId);
  const resetStore = usePeerWebRTCStore(state => state.resetStore);

  useEffect(() => {
    return () => {
      localVideoRef.current = null;
    }
  }, [])

  const createClosureForOnCallEvents = useCallback((browser: BrowserWebRtcCall) => {
    return (sender, args) => {
      console.log(args);
      console.log(sender);

      if (args.Type == CallEventType.MediaUpdate) {
        const margs = args as unknown as MediaUpdatedEventArgs;

        if (localVideoRef.current === null && margs.ConnectionId === ConnectionId.INVALID) {
          const videoElement = margs.VideoElement;

          if (!(videoElement instanceof HTMLVideoElement))
            return;

          setLocalVideo(videoElement);
          localVideoRef.current = videoElement;
          // this.mLocalVideo = videoElement;
          // this.mDiv.innerHTML += 'local video: ' + '<br>';
          // this.mDiv.appendChild(videoElement);
          // console.log('local video added resolution:' + videoElement.videoWidth + videoElement.videoHeight + ' fps: ??');
        } else if (margs.ConnectionId != ConnectionId.INVALID && !hasConnectionId(margs.ConnectionId.id)) {
          const videoElement = margs.VideoElement;
          const connectionId = margs.ConnectionId.id;

          onNewConnection(connectionId, videoElement);
          // this.mRemoteVideo[margs.ConnectionId.id] = videoElement;
          // this.mDiv.innerHTML += 'remote ' + this.mId + '<br>';
          // this.mDiv.appendChild(videoElement);
          // console.log('remote video added resolution:' + videoElement.videoWidth + videoElement.videoHeight + ' fps: ??');
        }
      } else if (args.Type == CallEventType.ListeningFailed) {
        if (netConfig.IsConference == false) {
          //in 1 to 1 calls there is a listener and a caller
          //if we try to listen first and it fails it likely means
          //the other side is waiting for an incoming call
          try {
            browser.Call(address);
          } catch (e) {
            console.error(e);
          }
        } else {
          //in conference mode there is no "caller" as everyone
          //just joins a single call via Listen call. if it fails
          //there is likely a network fault / configuration error
          console.error(':Listening failed. Server dead?');
        }
      } else if (args.Type == CallEventType.ConnectionFailed) {
        // alert(this.mId + ':connection failed');
      } else if (args.Type == CallEventType.CallEnded) {
        const callEndedEvent = args as CallEndedEventArgs;
        const connectionId = callEndedEvent.ConnectionId.id;

        // console.log(this.mId + ':call ended with id ' + callEndedEvent.ConnectionId.id);
        //document.body.removeChild(mRemoteVideo[callEndedEvent.ConnectionId.id]);
        //remove properly
        onRemoveConnection(connectionId);
      } else {
        console.log(args.Type);
      }
    };
  }, [setLocalVideo, onRemoveConnection, onNewConnection, address, hasConnectionId, netConfig]);

  useLayoutEffect(() => {
    const browser = new BrowserWebRtcCall(netConfig);

    setBrowserWebRTC(browser);

    return () => {
      try {
        browser.Dispose();
      } catch (e) {
        console.error(e);
      }
    };
  }, [netConfig]);

  useLayoutEffect(() => {
    if (!browserWebRTC)
      return;

    try {
      const onCallHandler = createClosureForOnCallEvents(browserWebRTC);

      browserWebRTC.addEventListener(onCallHandler);

      if (isCaller)
        browserWebRTC.Listen(address);
      else
        browserWebRTC.Call(address);

      return () => {
        browserWebRTC.removeEventListener(onCallHandler)
      };
    } catch (e) {
      console.error(e);
    }
  }, [browserWebRTC, createClosureForOnCallEvents, address, isCaller]);

  useLayoutEffect(() => {
    if (!browserWebRTC)
      return;

    try {
      browserWebRTC.Configure(mediaConfig);
    } catch (e) {
      console.error(e);
    }
  }, [browserWebRTC, browserWebRTC?.State, mediaConfig]);

  useInterval(() => {
    try {
      browserWebRTC.Update();
    } catch (e) {
      console.error(e);
    }
  }, !!browserWebRTC ? 50 : null);

  useEffect(() => {
    return () => resetStore();
  }, [resetStore]);

  return useMemo(() => [localVideo, Object.values(remoteVideos)], [localVideo, remoteVideos]);
}

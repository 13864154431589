import Select from 'antd/lib/select'
import * as S from './styles'

import styles from './Select.module.css'
import React from 'react'

const { Option } = Select

const AntdSelect = ({ values, valuesInObject, ...rest }) => (
  <S.AntdSelect
    size="large"
    dropdownClassName={styles.dropdown}
    {...rest}
  >
    {values?.map((value) => (
      <Option value={value} key={value}>
        {value}
      </Option>
    ))}
    {valuesInObject?.data?.map((item) => (
      <Option value={item[valuesInObject.key]} key={item[valuesInObject.key]}>
        {item[valuesInObject.value]}
      </Option>
    ))}
  </S.AntdSelect>
)

export default AntdSelect

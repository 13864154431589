import Select from 'antd/lib/select'
import styled from 'styled-components'

export const AntdSelect = styled(Select)`
  height: 2.5rem;
  font-size: 10px;
  
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input),
  .ant-select-selector {
    max-height: 4.8em  !important;
    height: 100%  !important;
    padding: 0 1.7em  !important;
    border: 1px solid #e0e0e0  !important;
    border-radius: 8px !important;
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input):hover,
  .ant-select-selector:hover {
    border-color: #bdbdbd;
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input),
  .ant-select-selector,
  .ant-select-selection-item,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input),
  .ant-select-selector,
  .ant-select-selection-placeholder {
    font-weight: 500;
    font-size: 1em;
    line-height: 1;
  }

  .ant-select-focused.ant-select-lg.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #2e8cff;
  }

  .ant-select-focused.ant-select-lg.ant-select-single:not(.ant-select-customize-input)  span.ant-select-arrow {
    transform: rotate(180deg);
  }

  span.ant-select-arrow {
    width: 1.3em;
    height: 0.9em;
    right: 1.7em;
  }

  .ant-select-item {
    font-weight: 500;
    font-size: 1em;
    color: #222222;
    padding: 1.1em 1.7em 1.1em 1.7em;
    height: 4em;
  }

  .ant-select-item.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #f2f6fc;
    color: #2e8cff;
  }

  .ant-select-item.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #f2f6fc;
    color: #2e8cff;
    font-weight: 500;
  }
`

import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { toggleMicrophone } from './services'
import { environment } from '../../environment'

const defaultState = {
  isMicrophoneOn: null,
  audioTrack: null
}

export type UseMicrophoneStore = {
  microphoneId: string | null;
  isMicrophoneOn: boolean | null;
  audioTrack: MediaStreamTrack | null;

  setMicrophone: (microphoneId: string) => void;
  setAudioTrack: (audioTrack: MediaStreamTrack) => void;
  setMicrophoneState: (isMicrophoneOn: boolean) => void;
  toggleMicrophone: () => void;
  resetStore: () => void;
}

export const useMicrophoneStore = create<UseMicrophoneStore>(
  devtools(
    persist((set, get) => ({
      microphoneId: 'default',
      ...defaultState,
      setMicrophone: (microphoneId) => set({ microphoneId }),
      setAudioTrack: (audioTrack) => set({ audioTrack }),
      toggleMicrophone: toggleMicrophone(set, get),
      setMicrophoneState: (isMicrophoneOn) => set({ isMicrophoneOn }),
      resetStore: () => set({ ...defaultState }),
    }), {
      name: '@zustand/useMicrophone',
      version: environment.zustandStoreVersion,
      onRehydrateStorage: () => {
        return savedState => {
          useMicrophoneStore.setState({
            ...defaultState,
            microphoneId: savedState?.microphoneId || 'default',
            isMicrophoneOn: savedState?.isMicrophoneOn,
          })
        }
      }
    }),
    '@zustand/useMicrophone'
  )
)

import create from 'zustand';
import { devtools } from 'zustand/middleware';

const defaultState = {
  remoteVideos: {},
  localVideo: null,
};

export type UsePeerWebRTC = {
  remoteVideos: Record<number, HTMLVideoElement | null>;

  localVideo: HTMLVideoElement | null;
  setLocalVideo: (video: HTMLVideoElement) => void;

  resetStore: () => void;
  onNewConnection: (connectionId: number, videoElement: HTMLVideoElement) => void;
  onRemoveConnection: (connectionId: number) => void;

  hasConnectionId: (connectionId: number) => boolean;
}

export const usePeerWebRTCStore = create<UsePeerWebRTC>(
  devtools(
    (set, get) => ({
      ...defaultState,
      onNewConnection: (connectionId, videoElement) => {
        if (!(videoElement instanceof HTMLVideoElement))
          return;

        const remoteVideos = get().remoteVideos;

        set({
          remoteVideos: {
            ...remoteVideos,
            [connectionId]: videoElement,
          },
        });
      },
      onRemoveConnection: (connectionId) => {
        const remoteVideos = get().remoteVideos;

        set({
          remoteVideos: {
            ...remoteVideos,
            [connectionId]: null,
          },
        });
      },
      setLocalVideo: localVideo => set({ localVideo }),
      hasConnectionId: (connectionId) => {
        const remoteVideos = get().remoteVideos;

        return !!remoteVideos[connectionId];
      },
      resetStore: () => set({ ...defaultState }),
    }),
    '@zustand/usePeerWebRTC',
  ),
);

import styled from 'styled-components'
import { resetButton } from '../../styles/recipes/reset-button'
import Tooltip from 'antd/lib/tooltip'

export const MicrophoneButton = styled.button`
  ${resetButton};
  
  background-color: white;
  border: 1px solid #EDEDFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.17);
  border-radius: 50%;
  
  display: flex;
  justify-content: center;
  align-items: center;

  height: 2.5rem;
  width: 2.5rem;
  
  padding: .5rem;
  
  &:disabled {
    opacity: .6;
  }
`

export const AntkTooltip = styled(Tooltip)``

export const MicrophoneButtonIcon = styled.img`
  height: 100%;
  width: 100%
`

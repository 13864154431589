import React from 'react'

import * as S from './styles'

import iconMicrophoneOn from '../../assets/icon-microphone-on.svg'
import iconMicrophoneOff from '../../assets/icon-microphone-off.svg'

function Microphone ({ className, isMicrophoneOn, toggleMicrophone }) {
  const tooltipMessage = isMicrophoneOn
      ? 'Close the Microphone'
      : 'Click to open microphone'

  return (
    <S.AntkTooltip placement="top" title={tooltipMessage}>
      <S.MicrophoneButton className={className} onClick={toggleMicrophone}>
        <S.MicrophoneButtonIcon src={isMicrophoneOn ? iconMicrophoneOn : iconMicrophoneOff}
                                alt="Click to request to open microphone."/>
      </S.MicrophoneButton>
    </S.AntkTooltip>
  )
}

export default Microphone

import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { environment } from '../../environment'

const defaultValues = {
  meetingId: null,
};

export type UseMeetingStore = {
  meetingId: string | null;
  isCaller: boolean;

  setMeetingId: (meetingId: string) => void;
  setIsCaller: (isCaller: boolean) => void;
  resetStore: () => void;
}

export const useMeetingStore = create<UseMeetingStore>(
  devtools(
    persist(
      (set, get) => ({
        meetingId: null,
        isCaller: null,
        setMeetingId: (meetingId) => set({ meetingId }),
        setIsCaller: (isCaller) => set({ isCaller }),
        resetStore: () => set({ ...defaultValues }),
      }), {
        name: '@zustand/useMeeting',
        version: environment.zustandStoreVersion,
      }),
    '@zustand/useMeeting',
  ),
)

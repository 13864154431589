export const resetButton = `
  outline: none;
  appearance: none;
  background: none;
  border: none;
  padding: 0;

  cursor: pointer;
  
  transition: opacity 500ms ease;
  
  &:disabled {
    cursor: no-drop;
    opacity: .6;
  }
`
